import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
	private _resolved = new Subject<string>();
	resolved = this._resolved.asObservable();

	private _reseted = new Subject<boolean>();
	reseted = this._reseted.asObservable();

	private _executed = new Subject<boolean>();
	executed = this._executed.asObservable();

  constructor() { }

	resolve(token: string){
		this._resolved.next(token);
	}

	reset(){
		this._reseted.next(true);
	}

	execute(){
		this._executed.next(true);
	}
}
