import {DateAdapter} from '@angular/material/core';

export const Custom_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMMM',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'YYYY MMMM'
  }
};

export class MaterialCustomDateAdapter extends DateAdapter<Date> {
  
  JalaliDate = {
    g_days_in_month: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  };
  
  constructor() {
    super();
    super.setLocale('en');
  }
  
  getYear(date: Date): number {
    return parseInt(new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(date));
  }
  
  getMonth(date: Date): number {
    return date.getMonth();
  }
  
  getDate(date: Date): number {
    return parseInt(new Intl.DateTimeFormat('en-US', {day: 'numeric'}).format(date));
  }
  
  getDayOfWeek(date: Date): number {
    let weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let weekDay = new Intl.DateTimeFormat('en-US', {weekday: 'short'}).format(date);
    return weekDays.findIndex(q => q === weekDay);
  }
  
  getMonthName(monthNumber: number, style: 'long' | 'short' | 'narrow') {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString('en-US', {month: style});
  }
  
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return [...Array(12).keys()].map((m) => this.getMonthName(m, style));
  }
  
  getDateNames(): string[] {
    const valuesArray = Array(31);
    for (let i = 0; i < 31; i++) {
      valuesArray[i] = String(i + 1);
    }
    return valuesArray;
  }
  
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    let week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    switch (style) {
      case 'long':
        return week;
      case 'short':
        return week;
      case 'narrow':
        return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    }
  }
  
  getYearName(date: Date): string {
    return new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(date);
  }
  
  getFirstDayOfWeek(): number {
    return 0;
  }
  
  getNumDaysInMonth(date: Date): number {
    let month = this.getMonth(date);
    return new Date(date.getUTCFullYear(), month + 1, 0).getDate();
  }
  
  clone(date: Date): Date {
    return new Date(date);
  }
  
  createDate(year: number, month: number, date: number): Date {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    return new Date(year, month, date);
  }
  
  today(): Date {
    return new Date(this.getCurrentDate());
  }
  
  getCurrentDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
  }
  
  parse(value: any, parseFormat: string | string[]): Date | null {
    let yearIndex = -1, monthIndex = -1, dayIndex = -1;
    let year = 1, month = 1, day = 1;
    switch (parseFormat) {
      case 'YYYY/MM/DD':
        yearIndex = 0;
        monthIndex = 5;
        dayIndex = 8;
        break;
      case 'YYYY MMMM':
        yearIndex = 0;
        monthIndex = 5;
        break;
    }
    if (yearIndex >= 0) {
      year = parseInt(value.substring(yearIndex, 4));
    }
    if (monthIndex >= 0) {
      month = parseInt(value.substring(monthIndex, 7));
    }
    if (dayIndex >= 0) {
      day = parseInt(value.substring(dayIndex, 10));
    }
    return new Date(year, month - 1, day);
  }
  
  format(date: Date, displayFormat: string): string {
    let year = this.getYear(date), month = this.getMonth(date) + 1, day = this.getDate(date);
    if (displayFormat == 'YYYY MMMM') {
      let monthName = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(date);
      return String(year).padStart(4, '0') + ' ' + monthName;
    } else if (displayFormat === 'YYYY/MM') {
      return String(year).padStart(4, '0') + '/' + String(month).padStart(2, '0');
    } else {
      return String(year).padStart(4, '0') + '/' + String(month).padStart(2, '0') + '/' + String(day).padStart(2, '0');
    }
  }
  
  addCalendarYears(date: Date, years: number): Date {
    let d = this.clone(date);
    d.setFullYear(date.getFullYear() + years);
    return d;
  }
  
  addCalendarMonths(date: Date, months: number): Date {
    let d: Date = this.clone(date);
    d.setMonth(d.getMonth() + months);
    return d;
  }
  
  addCalendarDays(date: Date, days: number): Date {
    let d = this.clone(date);
    d.setDate(date.getDate() + days);
    return d;
  }
  
  addCalendarWeeks(date: Date, weeks: number) {
    let d = this.clone(date);
    d.setDate(date.getDate() + weeks * 7);
    
    return d;
  }
  
  toIso8601(date: Date): string {
    return date.toISOString();
  }
  
  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }
  
  isValid(date: Date): boolean {
    return !isNaN(Date.parse(date.toString()));
  }
  
  invalid(): Date {
    return new Date('');
  }
  
  deserialize(value: Date): Date {
    return value;
  }
}
