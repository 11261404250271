import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Locale } from 'src/app/shared/models/locale.model';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../shared/services/http.service';
import { TranslationService } from '../../shared/services/translation.service';
import { CartService } from '../../shared/services/cart.service';
import { ModalHelperComponent } from '../../shared/components/modal-helper/modal-helper.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationClientService {
  mobile = '';
  customerName: BehaviorSubject<string> = new BehaviorSubject<string>(
    localStorage.getItem('customerName') ?? ''
  );
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  jwtHelper: JwtHelperService;
  locale: Locale = new Locale();
  translations: { [id: string]: string } = {};

  constructor(
    public dialog: MatDialog,
    private http: HttpService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translation: TranslationService,
  ) {
    this.translations['customer-logout-message'] = 'Customer log out message';
    this.translations['ok'] = 'Ok';
    this.translations['textLogOut'] = 'Are you sure about logging out?';
    this.translations['userLogOut'] = 'User exit';

    this.locale = JSON.parse(localStorage.getItem('locale') ?? '{[]}');
    this.jwtHelper = new JwtHelperService();
    const token = localStorage.getItem('Customerjwt');
    if (
      token &&
      token !== 'undefined' &&
      !this.jwtHelper.isTokenExpired(token)
    ) {
      this.mobile = localStorage.getItem('mobile')!;
      this.customerName.next(localStorage.getItem('customerName')!);
      this.isLoggedIn.next(true);
    }
    window.addEventListener('storage', this.handleStorageEvent);
    this.translation.getTranslations(this.translations).subscribe((q) => {
      this.translations = q;
    });
  }

  private handleStorageEvent = (event: StorageEvent): void => {
    if (event.key === 'customerMsg') {
      if (localStorage.getItem('customerMsg') === 'CustomerLogin') {
        this.mobile = localStorage.getItem('mobile')!;
        this.customerName.next(localStorage.getItem('customerName')!);
        this.isLoggedIn.next(true);
      } else if (localStorage.getItem('customerMsg') === 'CustomerLogout') {
        this.isLoggedIn.next(false);
        this.router.navigateByUrl('/login').then();
      }
    }
  };

  login(customerPass: any): Observable<any> {
    return this.http.request(
      'CustomerLogin',
      'Login',
      customerPass,
      'user',
      'POST'
    );
  }

  logOut(): void {
    this.dialog
      .open(ModalHelperComponent, {
        data: {
          Title: this.translations['userLogOut'],
          Message: this.translations['textLogOut'],
        },
        panelClass: ['form-dialog-small'],
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.http
            .request('CustomerLogin', 'Logout', '', 'customer', 'POST')
            .subscribe((q) => {
              localStorage.removeItem('Customerjwt');
              localStorage.removeItem('mobile');
              localStorage.removeItem('customerName');
              localStorage.removeItem('currencyUnit');
              localStorage.removeItem('customerId');
              localStorage.setItem('customerMsg', 'CustomerLogout');
              //this.cartService.update();
              this.isLoggedIn.next(false);
              this.mobile = '';
              this.snackBar.open(
                this.translations['customer-logout-message'],
                this.translations['ok'],
                {
                  duration: 2000,
                  direction: this.locale.isRtl ? 'ltr' : 'ltr',
                }
              );
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 500);
            });
        }
      });
  }
}
