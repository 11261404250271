import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar, public router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.statusText === 'Unknown Error') {
          this.snackBar.open(`Server Error`, 'Ok', {
            duration: 5000,
            direction: 'ltr'
          });
        } else if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
        } else {
          // The backend returned an unsuccessful response code.
          if (error.status === 500) {
            this.snackBar.open(`Server Error`, 'Ok', {
              duration: 5000,
              direction: 'ltr'
            });
          }
        }
        throw error;
      })
    );
  }
}
