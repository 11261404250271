import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddUpdateFilterComponent} from './add-update-filter.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatSelectSearchModule} from '../mat-select-search/mat-select-search.module';
import {MatDatepickerModule} from '@angular/material/datepicker';


@NgModule({
	declarations: [
		AddUpdateFilterComponent,
	],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatIconModule,
		MatButtonModule,
		FlexModule,
		MatProgressSpinnerModule,
		FormsModule,
		MatInputModule,
		MatChipsModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatSelectSearchModule,
		MatDatepickerModule,
	],
})
export class MyFilterModule {
}
