<div class="dialog-header">
  <h2>{{data.Title}}</h2>
</div>
<div class="dialog-body">
  <pre>{{data.Message}}</pre>
  <div *ngIf="data.bDesc">
    <mat-form-field>
      <mat-label>{{Label}}</mat-label>
      <input maxlength="500" type="text" matInput [(ngModel)]="Desc" name="description">
    </mat-form-field>
  </div>
</div>
<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px" class="dialog-footer">
  <button  mat-flat-button color="warn" class="w-[100px] min-h-[35px] bg-[--first-color] rounded-[45px]"
    [mat-dialog-close]="data.bDesc ? Desc : true">
    <span >{{translations['ok']}}</span>
  </button>
  <button *ngIf="!data.notShowCloseBtn" mat-flat-button color="primary" [mat-dialog-close]="false">
    <span >{{translations['text-button-cancel']}}</span>
  </button>
</div>