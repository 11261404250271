import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toPersianDate'
})
export class ToPersianDatePipe implements PipeTransform {

	transform(value: string | null | undefined, withTime: boolean = true, showPrefix: boolean = true, showDay: boolean = false, showYearMonth: boolean = false): string {
		if (!value) {
			return '';
		}
		//TODO
		let prefix: string = '';
		let inputDate: Date = new Date(value);
		let today: Date = new Date();
		let tomorrow: Date = new Date();
		let 	jalaliDate = new Intl.DateTimeFormat("fa-u-ca-persian-nu-latn", { year: 'numeric', month: 'numeric', day:'2-digit',hour:'2-digit',minute:'2-digit' }).formatToParts(inputDate);
		tomorrow.setDate(today.getDate() + 1)
		let yesterday = new Date();
		yesterday.setDate(today.getDate() - 1)
		if (showPrefix) {
			if (inputDate.getFullYear() === today.getFullYear() && inputDate.getMonth() === today.getMonth() && inputDate.getDate() === today.getDate()) {
				prefix = 'امروز ';
			} else if (inputDate.getFullYear() === tomorrow.getFullYear() && inputDate.getMonth() === tomorrow.getMonth() && inputDate.getDate() === tomorrow.getDate()) {
				prefix = 'فردا ';
			} else if (inputDate.getFullYear() === yesterday.getFullYear() && inputDate.getMonth() === yesterday.getMonth() && inputDate.getDate() === yesterday.getDate()) {
				prefix = 'دیروز '
			}

			switch (inputDate.getDay()) {
				case 0:
					prefix += 'یکشنبه ';
					break;

				case 1:
					prefix += 'دوشنبه ';
					break;

				case 2:
					prefix += 'سه‌شنبه ';
					break;

				case 3:
					prefix += 'چهارشنبه ';
					break;

				case 4:
					prefix += 'پنج‌شنبه ';
					break;

				case 5:
					prefix += 'جمعه ';
					break;

				case 6:
					prefix += 'شنبه ';
					break;
				default:
					break;
			}
		}
		if (showDay) {
			switch (inputDate.getDay()) {
				case 0:
					prefix = 'یکشنبه ';
					break;

				case 1:
					prefix = 'دوشنبه ';
					break;

				case 2:
					prefix = 'سه‌شنبه ';
					break;

				case 3:
					prefix = 'چهارشنبه ';
					break;

				case 4:
					prefix = 'پنج‌شنبه ';
					break;

				case 5:
					prefix = 'جمعه ';
					break;

				case 6:
					prefix = 'شنبه ';
					break;
				default:
					break;
			}
		}
		if (!withTime && !showYearMonth) {
			return prefix + jalaliDate[0].value + jalaliDate[1].value + jalaliDate[2].value + jalaliDate[3].value + jalaliDate[4].value;
		}
		else if (showYearMonth) {
			return prefix + jalaliDate[0].value + jalaliDate[1].value + jalaliDate[2].value;
		}
		else {
			return prefix + jalaliDate[0].value + jalaliDate[1].value + jalaliDate[2].value + jalaliDate[3].value + jalaliDate[4].value  +' '+ jalaliDate[6].value + jalaliDate[7].value + jalaliDate[8].value;
		}
	}

}
