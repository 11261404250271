import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, Observer, Subject, throwError } from 'rxjs';
import { FileUploadResult } from '../models/file-upload.model';
import { CurrencyUnit } from '../models/currnecy-unit.model';
import { Locale } from '../models/locale.model';
import { PermissionString } from '../models/permission-string.model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyDate } from '../classes/class';
import { MatDialog } from '@angular/material/dialog';
import { MediumChat } from '../models/medium-chat.model';
import { Permission } from '../models/permission.model';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  permissionString: PermissionString[] = [];
  userOnline: number = 0;
  messagecount: number = 0;
  orderCount: number = 0;
  customerNoteCount: number = 0;
  OrganizationNoteCount: number = 0;
  offerCount: number = 0;
  isUsedRecaptcha: boolean = false;
  employmentcount: number = 0;
  summessagecount: number = 0;
  userOnlineName: string[] = [];
  userChangeSelf: boolean = false;
  userChangeToken: boolean = false;
  changeTokenAdmin: boolean | undefined;
  changeTokenService: boolean | undefined;
  changeCustomerName: boolean = false;
  changeProfileCustomer: boolean = false;
  changeProfileMenu: boolean = false;
  loadingMenu: boolean = false;
  checkTokenComponent: any[] = [];
  forceLogOut: boolean | undefined;
  permissionStringChange: Subject<PermissionString[]> = new Subject<PermissionString[]>();
  userOnlineChange: Subject<number> = new Subject<number>();
  customerChange: Subject<boolean> = new Subject<boolean>();
  customerProfileChange: Subject<boolean> = new Subject<boolean>();
  customerProfileMenuChange: Subject<boolean> = new Subject<boolean>();
  allMessageCount: Subject<number> = new Subject<number>();
  allEmploymentMessageCount: Subject<number> = new Subject<number>();
  allOrderCount: Subject<number> = new Subject<number>();
  allCustomerNoteCount: Subject<number> = new Subject<number>();
  allOrganizationNoteCount: Subject<number> = new Subject<number>();
  allOfferCount: Subject<number> = new Subject<number>();
  allsumMessageCount: Subject<number> = new Subject<number>();
  userOnlineNameChange: Subject<string[]> = new Subject<string[]>();
  userSelfChange: Subject<boolean> = new Subject<boolean>();
  loadingMenuSubject: Subject<boolean> = new Subject<boolean>();
  uniqueGuid: string = '';
  nowLogin: boolean = false;
  headerColor: boolean = false;
  headerColorChange: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private router: Router, private dialogRef: MatDialog) {
    this.permissionStringChange.subscribe((value) => {
      this.permissionString = value;
    });
    this.userOnlineChange.subscribe((value) => {
      this.userOnline = value;
    });
    this.loadingMenuSubject.subscribe((value) => {
      this.loadingMenu = value;
    });
    this.allMessageCount.subscribe((value) => {
      this.messagecount = value;
    });
    this.headerColorChange.subscribe((value) => {
      this.headerColor = value;
    });
    this.allOrderCount.subscribe((value) => {
      this.orderCount = value;
    });
    this.allCustomerNoteCount.subscribe((value) => {
      this.customerNoteCount = value;
    });
    this.allOrganizationNoteCount.subscribe((value) => {
      this.OrganizationNoteCount = value;
    });
    this.allOfferCount.subscribe((value) => {
      this.offerCount = value;
    });
    this.allEmploymentMessageCount.subscribe((value) => {
      this.employmentcount = value;
    });
    this.allsumMessageCount.subscribe((value) => {
      this.summessagecount = value;
    });
    this.userOnlineNameChange.subscribe((value) => {
      this.userOnlineName = value;
    });
    this.userSelfChange.subscribe((value) => {
      this.userChangeSelf = value;
    });
    this.customerChange.subscribe((value) => {
      this.changeCustomerName = value;
    });
    this.customerProfileChange.subscribe((value) => {
      this.changeProfileCustomer = value;
    });
    this.customerProfileMenuChange.subscribe((value) => {
      this.changeProfileMenu = value;
    });
  }

  public request<T>(ControllerName: string, FunctionName: string, _formData: any = null, userType: 'user' | 'admin' | 'customer' = 'user', method: 'POST' | 'GET' | 'DELETE' | 'PUT' = 'GET', localeKey: 'locale' | 'adminLocale' = 'locale'): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      ControllerName = ControllerName.replace('Controller', '');
      let header: HttpHeaders;
      let formData = JSON.parse(JSON.stringify(_formData), this.dateTimeReviverReq);

      if (userType === 'admin') {
        header = new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept-Language': HttpService.tryJson<Locale>(localeKey, 'key')?.key ?? '',
          'Authorization': userType === 'admin' ? `Bearer ${localStorage.getItem('Adminjwt')}` : '',
          'X-Display-Currency': HttpService.tryJson<CurrencyUnit>('currencyUnit', 'key')?.key,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        });
      } else if (userType === 'customer') {
        header = new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept-Language': HttpService.tryJson<Locale>(localeKey, 'key')?.key ?? '',
          'Authorization': userType === 'customer' ? `Bearer ${localStorage.getItem('Customerjwt')}` : '',
          'X-Display-Currency': HttpService.tryJson<CurrencyUnit>('currencyUnit', 'key')?.key,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        });
      } else {
        header = new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept-Language': HttpService.tryJson<Locale>(localeKey, 'key')?.key ?? '',
          'X-Display-Currency': HttpService.tryJson<CurrencyUnit>('currencyUnit', 'key')?.key,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        });
      }

      let req: Observable<HttpEvent<T>>;

      if (method === 'GET') {
        formData = this.FixNulls(formData);
        let params: HttpParams = new HttpParams({ fromObject: formData });
        req = this.http.request<T>(new HttpRequest(method, `api/${ControllerName}/${FunctionName}`, null, {
          headers: header,
          params: params
        }));
      } else {
        req = this.http.request<T>(new HttpRequest(method, `api/${ControllerName}/${FunctionName}`, JSON.stringify(formData), { headers: header }));
      }

      req.subscribe({
        next: (event) => {
          if (event && event.type === HttpEventType.Response) {
            if ((<any>event).headers && (<any>event).headers.get('Token-Expired')) {
              if ((<any>event).headers.get('Token-Expired') == 'Refresh') {
                this.changeTokenService = false;
                setTimeout(() => {
                  this.request<T>(ControllerName, FunctionName, _formData, userType, method).subscribe(r => {
                    observer.next(r);
                  });
                }, 1000);
              } else if ((<any>event).headers.get('Token-Expired') == 'TokneUndefined') {
                this.dialogRef.closeAll();
                if (userType == 'admin') {
                  this.snackBar.open('Access Denied', 'Ok', {
                    duration: 1000,
                    direction: 'ltr'
                  });
                  this.router.navigate(['/admin/login']);
                } else {
                  this.router.navigate(['/login'], { queryParams: { invalidToken: 'true' } });
                }
              } else if ((<any>event).headers.get('Token-Expired') == 'Expired') {
                this.dialogRef.closeAll();
                if (userType == 'admin') {
                  this.snackBar.open('Access Denied', 'Ok', {
                    duration: 1000,
                    direction: 'ltr'
                  });
                  this.router.navigate(['/admin/login']);
                } else {
                  this.router.navigate(['/login'], { queryParams: { invalidToken: 'true' } });
                }
              }
            } else {
              let a: any;
              if (event.type === HttpEventType.Response && event.ok) {
                a = <T>(<any>event).body
              } else if (event.type === HttpEventType.Response) {
                a = <T>(<any>event);
              }

              a = JSON.parse(JSON.stringify(a), this.dateTimeReviverRes)
              observer.next(a);
            }
          }
        },
        error: (e) => observer.error(e)
      }
      );
    });
  }

  dateTimeReviverReq(key: string, value: any) {
    let a: any;
    let b: RegExp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/gi;
    if (b.test(value)) {
      a = new Date(value);
      if (a != 'Invalid Date') {
        let offset = a.getTimezoneOffset();
        let localStorageOffset: number = +(localStorage.getItem('timeZoneOffset') ?? '') || offset;
        let diff = offset - localStorageOffset;
        a.setMinutes(a.getMinutes() - diff);
        a = a.toISOString();
        return a;
      }
    }
    return value;
  }

  dateTimeReviverRes(key: string, value: any) {
    let a: any;
    let b: RegExp = /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}Z/gi;
    if (b.test(value)) {
      a = new Date(value);
      if (a != 'Invalid Date') {
        let offset = a.getTimezoneOffset();
        let localStorageOffset: number = +(localStorage.getItem('timeZoneOffset') ?? '') || offset;
        let diff = offset - localStorageOffset;
        a.setMinutes(a.getMinutes() + diff);
        a = a.toISOString();
        return a;
      }
    }
    return value;
  }

  private FixNulls(formData: any): any {
    for (let x in formData) {
      if (formData[x] instanceof Object) {
        formData[x] = this.FixNulls(formData[x]);
      } else if (formData[x] === undefined || formData[x] === null || formData[x] === '') {
        delete formData[x];
      }
    }
    return formData;
  }

  private static tryJson<T>(str: string, els: string): T {
    try {
      return JSON.parse(localStorage.getItem(str) || `{"${els}":""}`);
    } catch (e) {
      return JSON.parse(`{"${els}":""}`);
    }
  }

  downloadFile(filename: string, responseType: 'blob' | 'text' = 'blob'): Observable<any> {
    const httpOptions = {
      responseType: responseType as 'json'
    };
    return this.http.get(`/` + filename, httpOptions);
  }

  downloadFileWithAuth(filename: string,customerAuth:boolean=false): Observable<any> {
    let jwt = !customerAuth ? localStorage.getItem('Adminjwt') : localStorage.getItem('Customerjwt');
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem(jwt!)}`
      })
    };
    return this.http.get(`/` + filename, httpOptions);
  }

  downloadDocFile(path: string, type: 'user' | 'admin' = 'admin'): Observable<any> {
    let httpOptions: any = {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders({
        'Authorization': type === 'user' ? '' : `Bearer ${localStorage.getItem('Adminjwt')}`,
        'Accept-Language': HttpService.tryJson<Locale>('locale', 'key')?.key
      })
    };
    return this.http.get<HttpResponse<Blob>>(path, httpOptions);
  }

  downloadVdieoGuide(path: string): Observable<any> {
    let httpOptions: any = {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders({
        'Accept-Language': HttpService.tryJson<Locale>('locale', 'key')?.key
      })
    };
    return this.http.get<HttpResponse<Blob>>(path, httpOptions);
  }

  public UploadFile(file: File, lang: string, isSetting: string, uploadThumb: boolean = false, mediaId: string = '', pathFile: string = '', userType: 'client' | 'admin' | 'customer' | '' = ''): Observable<FileUploadResult | number> {
    return new Observable((observer: Observer<FileUploadResult | number>) => {
      let header: HttpHeaders;
      if (userType === 'admin') {
        header = new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('Adminjwt')}`
        });
      } else if (userType === 'customer') {
        header = new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('Customerjwt')}`
        });
      } else {
        header = new HttpHeaders({});
      }

      let formData: FormData = new FormData();
      formData.append(file.name, file);
      formData.append('isSetting', isSetting);
      formData.append('Lang', lang);
      formData.append('PathFile', pathFile);
      formData.append('mediaId', mediaId);
      if (!uploadThumb) {
        const uploadReq =
          userType === 'admin' || userType === 'customer' ? new HttpRequest('POST', '/api/UploadFile/Put', formData, {
            reportProgress: true,
            headers: header
          }) : new HttpRequest('POST', '/api/UploadFile/PutFileClient', formData, {
            reportProgress: true,
            headers: header
          });

        this.http.request(uploadReq).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            let percent = 0;
            if (event.total && event.loaded) {
              percent = (event.loaded / event.total) * 100;
            }
            observer.next(percent);
          }

          if (event.type === HttpEventType.Response && event.ok) {
            observer.next(<FileUploadResult>(<any>event).body);
          } else if (event.type === HttpEventType.Response) {
            observer.next(<FileUploadResult>(<any>event));
          }
        });
      } else {
        const uploadReq =
          userType === 'admin' || userType === 'customer' ? new HttpRequest('POST', '/api/UploadFile/UploadThumb', formData, {
            reportProgress: true,
            headers: header
          }) : new HttpRequest('POST', '/api/UploadFile/UploadThumbClient', formData, {
            reportProgress: true,
            headers: header
          });

        this.http.request(uploadReq).subscribe(event => {
          if (event.type === HttpEventType.Response && event.ok) {
            observer.next(<FileUploadResult>(<any>event).body);
          } else if (event.type === HttpEventType.Response) {
            observer.next(<FileUploadResult>(<any>event));
          }
        });
      }
    });
  }

  public UploadDocument(file: File, fileName: string = ''): Observable<FileUploadResult | number> {
    return new Observable((observer: Observer<FileUploadResult | number>) => {
      let header: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('Adminjwt')}`,
        'Accept-Language': HttpService.tryJson<Locale>('locale', 'key')?.key
      });
      let formData: FormData = new FormData();
      formData.append(file.name, file);
      formData.append('fileName', fileName);
      this.http.request(new HttpRequest('POST', '/api/document/uploadFile', formData, {
        reportProgress: true,
        headers: header
      }))
        .subscribe(event => {
          if (event.type === HttpEventType.Response && event.ok) {
            observer.next(<FileUploadResult>(<any>event).body);
          } else if (event.type === HttpEventType.Response) {
            observer.next(<FileUploadResult>(<any>event));
          }
        });
    });
  }

  public UploadDocumentGuide(file: File, fileName: string = ''): Observable<FileUploadResult | number> {
    return new Observable((observer: Observer<FileUploadResult | number>) => {
      let header: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('Adminjwt')}`,
        'Accept-Language': HttpService.tryJson<Locale>('locale', 'key')?.key
      });
      let formData: FormData = new FormData();
      formData.append(file.name, file);
      formData.append('fileName', fileName);
      this.http.request(new HttpRequest('POST', '/api/guide/uploadFile', formData, {
        reportProgress: true,
        headers: header
      }))
        .subscribe(event => {
          if (event.type === HttpEventType.Response && event.ok) {
            observer.next(<FileUploadResult>(<any>event).body);
          } else if (event.type === HttpEventType.Response) {
            observer.next(<FileUploadResult>(<any>event));
          }
        });
    });
  }

  public UploadVideoGuide(file: File, fileName: string = ''): Observable<FileUploadResult | number> {
    return new Observable((observer: Observer<FileUploadResult | number>) => {
      let header: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('Adminjwt')}`,
        'Accept-Language': HttpService.tryJson<Locale>('locale', 'key')?.key
      });
      let formData: FormData = new FormData();
      formData.append(file.name, file);
      formData.append('fileName', fileName);
      this.http.request(new HttpRequest('POST', '/api/guide/uploadVideo', formData, {
        reportProgress: true,
        headers: header
      }))
        .subscribe(event => {
          if (event.type === HttpEventType.Response && event.ok) {
            observer.next(<FileUploadResult>(<any>event).body);
          } else if (event.type === HttpEventType.Response) {
            observer.next(<FileUploadResult>(<any>event));
          }
        });
    });
  }

  public generateCode(name: 'FactorCode' | 'ProductCode' | 'WarehouseCode' | 'UserCode' | 'MachineCode' | 'CustomerCode' | 'TicketCode' | 'DutyCode'): Observable<number> {
    return this.request<number>('Setting', 'GetNextTransactionSequence', { sequence: name }, 'user', 'GET');
  }

  public getDate(mode: 'Now' | 'Today' | 'CurrentWeek' | 'CurrentMonth' | 'GetWeekByDate' | 'GetMonthByDate' = 'Now', date: Date | null = null): Observable<MyDate> {
    return this.request<MyDate>('Setting', 'GetDate', { mode: mode, fromDate: date }, 'user', 'GET');
  }

  public getStateRecaptcha(): Observable<boolean> {
    return this.request('Setting', 'GetStateRecaptcha', '', 'user', 'GET');
  }

  public checkPermission(PermissionNum: number, isCheck: boolean = false): any {
    this.checkTokenComponent = this.permissionString;
    if (this.checkTokenComponent[PermissionNum] == 0) {
      if (!isCheck) {
        this.router.navigate(['/admin']).then();
        this.snackBar.open(`Access Denied`, 'Ok', {
          duration: 3000,
          direction: 'ltr'
        });
      }
      return false;
    } else if (this.checkTokenComponent[PermissionNum] == 1) {
      return true;
    }
    return false;
  }

  public UploadFileChat(files: File[]): Observable<MediumChat[]> {

    return new Observable((observer: Observer<MediumChat[]>) => {
      let formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i]);
      }
      let header: HttpHeaders = new HttpHeaders({});
      let uploadReq = new HttpRequest('POST', '/api/Chat/PutFiles', formData, {
        reportProgress: true,
        headers: header,
      });
      this.http.request(uploadReq).subscribe(event => {
        if (event.type === HttpEventType.Response && event.ok) {
          observer.next(<MediumChat[]>(<any>event.body));
        }
      });
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
    // return catchError();
  }
}
