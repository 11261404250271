import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHelperComponent } from './modal-helper.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [ModalHelperComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        FlexLayoutModule,
        MatIconModule,
    ],
    exports: [ModalHelperComponent]
})
export class ModalHelperModule { }
