import { ChangeDetectorRef, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { LogLevel } from "@microsoft/signalr";

@Injectable({
	providedIn: 'root'
})
export class SignalhubService {
	connection: any | undefined;
	randomGuid?: string | null = null;
	constructor() {
	}

	signalhubconnect() {
		this.randomGuid = localStorage.getItem('RandomGuid');
		this.connection = new signalR.HubConnectionBuilder().configureLogging(LogLevel.None)
			.withUrl('/signalrHub?randomGuid=' + this.randomGuid)
			.withAutomaticReconnect().build();
		this.connection.start();
	}
	async start() {
		try {
			await this.connection.start();
		} catch (error) {
		}
	}
}
