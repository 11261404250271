import {Injectable, NgModule} from '@angular/core';
import {PreloadingStrategy, Route, RouterModule, Routes} from '@angular/router';
import {catchError, mergeMap} from 'rxjs/operators';
import {Observable, of, timer} from 'rxjs';
import {ClientAuthGuard} from './client/login-client/client-auth.guard';
import {DynamicTableFillComponent} from './shared/components/dynamic-table-fill/dynamic-table-fill.component';

const routes: Routes = [
  
  {path: 'dynamic-form/:id', component: DynamicTableFillComponent},
  {path: 'dynamic-form/:id/:fid', component: DynamicTableFillComponent},
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), data: {preload: 'admin'}
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [ClientAuthGuard],
    data: {
      preload: 'account'
    }
  },
  {
    path: 'admin-profile',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    data: {
      preload: 'admin'
    }
  },
  {
    path: '', loadChildren: () => import('./client/client.module').then(m => m.ClientModule), data: {preload: 'client'}
  },
  {
    path: '**', redirectTo: ''
  }
];

@Injectable()
export class PreloadCustomModules implements PreloadingStrategy {
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (window.location?.href?.split('/')[3] && window.location.href.split('/')[3] === 'admin') {
      if (route.data && (<any>route.data)['preload'] && (<any>route.data)['preload'] === 'admin') {
        return fn().pipe(catchError(() => of(null)));
      }
    } else if (window.location?.href?.split('/')[3] && window.location.href.split('/')[3] === 'account') {
      if (route.data && (<any>route.data)['preload'] && (<any>route.data)['preload'] === 'account') {
        return fn().pipe(catchError(() => of(null)));
      }
    } else {
      if (route.data && (<any>route.data)['preload'] && (<any>route.data)['preload'] === 'client') {
        return timer(15000).pipe(
          mergeMap(() => {
            return fn().pipe(catchError(() => of(null)));
          })
        );
      }
    }
    return of(null);
  }
}

@NgModule({
  providers: [PreloadCustomModules],
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadCustomModules, anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
