import { Medium } from './medium.model';
import { Translation } from './translations.model';

export class Locale {
  localeId: string = '00000000-0000-0000-0000-000000000000';
  key: string = 'en';
  isDeleted: boolean = false;
  isRtl: boolean = false;
  isDefault: boolean = true;
  label: string = 'English';

  media: Medium[] = [];
  translations: Translation[] = [];
  flagNavigation: Medium | null = null;
  emoji: string = '';
}
