<form #AddEdit="ngForm" (ngSubmit)="addEditSubmit()" class="mainform" *ngIf="item && item.dynamicTableBodies && !submited">
    <div class="heading">
        <h2>{{item.formTitleView}}</h2>
        <h3>{{item.formDescriptionView}}</h3>
    </div>
    <ng-container *ngFor="let field of item.dynamicTableBodies">
        <ng-container *ngIf="step === field.step">
            <ng-container *ngIf="!field.relatedFieldId || values[field.relatedFieldId].dynamicTableOptionId === field.dynamicTableOptionId">
                <div class="field" [style.--fxinit]="field.division" [class.f1]="field.fieldType === 5" [ngSwitch]="field.fieldType" *ngIf="field && field.dynamicTableBodyId">
                    <ng-container *ngSwitchCase="0">
                        <!-- Number -->
                        <label class="title">
                            <b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b>
                            <input class="field" [name]="field.dynamicTableBodyId" type="number" dir="ltr" [step]="field.decimalPlacesView" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId].value" />
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <!-- Text -->
                        <label class="title">
                            <b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b>
                            <input class="field" [name]="field.dynamicTableBodyId" type="text" dir="auto" maxlength="500" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId].value" />
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="7">
                        <!-- Mobile -->
                        <label class="title">
                            <b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b>
                            <input class="field" [name]="field.dynamicTableBodyId" type="tel" pattern="^\+[1-9]{1}[0-9]{3,14}$" dir="auto" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId].value" />
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="8">
                        <!-- Email -->
                        <label class="title">
                            <b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b>
                            <input class="field" [name]="field.dynamicTableBodyId" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" dir="auto" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId].value" />
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="5">
                        <!-- Multiline -->
                        <label class="title">
                            <b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b>
                            <textarea class="field" [name]="field.dynamicTableBodyId" dir="auto" maxlength="500" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId].value"></textarea>
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <!-- Dropdown -->
                        <label class="title">
                            <b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b>
                            <select class="field" [name]="field.dynamicTableBodyId" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId]">
                                <option *ngFor="let option of field.dynamicTableOptions" [ngValue]="option">
                                    {{option.displayTextView}}
                                </option>
                            </select>
                        </label>
                    </ng-container>
                    <div class="vert" *ngSwitchCase="3">
                        <!-- Radio -->
                        <label class="title"><b>{{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span></b></label>
                        <div class="options" [class.h-auto]="field.division === 100">
                            <label class="option" *ngFor="let option of field.dynamicTableOptions">
                                <input [name]="field.dynamicTableBodyId" type="radio" [value]="option" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId]" />
                                <span>
                                    {{option.displayTextView}}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="vert" *ngSwitchCase="4">
                        <!-- Checkbox -->
                        <div class="options">
                            <label class="option">
                                <input [name]="field.dynamicTableBodyId" type="checkbox" [required]="field.required ?? false" [(ngModel)]="values[field.dynamicTableBodyId].value" />
                                <span>
                                    {{field.fieldTitleView}} <span class="req" *ngIf="field.required">*</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isWorking">
        <input class="submit" type="submit" value="Submit" name="submit" *ngIf="!AddEdit.invalid && step === maxStep" />
        <input class="submit invalid" type="button" value="Invalid" name="Invalid" *ngIf="AddEdit.invalid" disabled />
        <input class="submit" type="button" value="Continue" name="continue" *ngIf="!AddEdit.invalid && step < maxStep" (click)="continueClick()" />
        <input class="submit" type="button" value="Back" name="back" *ngIf="step > minStep" (click)="backClick()" />
    </ng-container>
    <div class="submit disable" *ngIf="isWorking">
        submit
    </div>
</form>
<div class="submited" *ngIf="submited">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 -960 960 960"
  >
    <path
      d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
    />
  </svg>
  <span>{{ translations["FORM_SUBMITTED"] }}</span>
</div>
