import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {StaticContents} from '../models/static-content.model';
import {HttpService} from './http.service';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private api: HttpService) {
  }

  statics: { [key: string]: StaticContents } = {};
  public staticsLoading: { [key: string]: boolean } = {};
  // public observeLoading: { [key: string]: Subject<boolean> } = {};
  // private arrayLoading: Subject<string[]> = new Subject<string[]>();

  getTranslation(key: string, localeKey: 'locale' | 'adminLocale' = 'locale'): Observable<StaticContents> {
    this.staticsLoading[key] = true;
    return new Observable<StaticContents>(observer => {
      if (!this.statics[key]) {
        this.api.request<StaticContents>('staticContent', 'GetStaticContentForClient', {key: key}, 'user', 'GET', localeKey).subscribe(n => {
          this.statics[key] = n;
          this.staticsLoading[key] = false;
          observer.next(this.statics[key] ?? '');
        });
        // this.observeLoading[key].pipe(filter(q => q)).subscribe(q => {
        //     this.staticsLoading[key] = false;
        //     observer.next(this.statics[key] ?? '');
        //     })
        // this.arrayLoading.subscribe(q => {
        //   if (q.some(w => w === key)){
        //     observer.next(this.statics[key]);
        //     this.staticsLoading[key] = false;
        //   }
        // })
      } else {
        this.staticsLoading[key] = false;
        observer.next(this.statics[key]);
      }
    });
  }

  getPageTranslation(keys: string[]): Observable<boolean> {
    let requests: string[] = [];
    keys?.forEach(key => {
      if (!this.statics[key] && !this.staticsLoading[key]) {
        requests.push(key);
      }
    });
    keys?.forEach(requests => {
      this.staticsLoading[requests] = true;
      // this.observeLoading[requests].next(false);
    });
    return new Observable<boolean>(observer => {
      if (requests.length > 0) {
        this.api.request<StaticContents[]>('staticContent', 'GetStaticContentForClientList', requests, 'user', 'POST')
          .subscribe(n => {
            n.forEach(x => {
              this.statics[x.key] = x;
              this.staticsLoading[x.key] = false;
              // this.observeLoading[x.key].next(false);
              // this.arrayLoading.next(keys);
            });
            observer.next(true);
          });
      } else {
        keys?.forEach(key => {
          this.staticsLoading[key] = false;
          // this.observeLoading[x.key].next(false);
          // this.arrayLoading.next(keys);
        });
        observer.next(false);
      }
    });
  }

  getTranslations(requests: { [key: string]: string }, localeKey: 'locale' | 'adminLocale' = 'locale'): Observable<{ [key: string]: string }> {
    return new Observable<{ [key: string]: string }>(observer => {
      let keys: string[] = Object.keys(requests);
      if (keys.length > 0) {
        this.api.request<StaticContents[]>('staticContent', 'GetStaticContentForClientList', keys, 'user', 'POST', localeKey)
          .subscribe(n => {
            n.forEach(x => {
              requests[x.key] = x.valueView;
            });
            observer.next(requests);
          });
      } else {
        observer.next(requests);
      }
    });
  }
}

