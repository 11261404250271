import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {PipesModule} from './shared/pipes/pipes.module';
import {ModalHelperModule} from './shared/components/modal-helper/modal-helper.module';
import {MyFilterModule} from './shared/components/my-filter/my-filter.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpErrorInterceptor} from './shared/services/http-error-interceptor';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MaterialPersianDateModule } from './shared/components/date-adapter/material.persian-date.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        ModalHelperModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        MyFilterModule,
        FlexLayoutModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        RouterModule,
        MatTooltipModule,
        MaterialPersianDateModule
    ],

    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
