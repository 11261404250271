import { DatePartPipe } from './date-part.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterUnitPipe } from './filter-unit.pipe';
import { ToPersianDatePipe } from './to-persian-date.pipe';
import { ActionTranslatePipe } from './action-translate.pipe';
import { ConvertSizeFilePipe } from './convert-size-file.pipe';
import { GetTimeIntervalPipe } from './get-time-interval.pipe';
import { HighlighterPipe } from './highlighter.pipe';
import { YearOrMonthSuffixPipe } from './year-or-month-suffix.pipe';

@NgModule({
  declarations: [
    FilterUnitPipe,
    ToPersianDatePipe,
    ActionTranslatePipe,
    ConvertSizeFilePipe,
    DatePartPipe,
    GetTimeIntervalPipe,
    HighlighterPipe,
    YearOrMonthSuffixPipe
  ],
  imports: [CommonModule],
  exports: [
    FilterUnitPipe,
    ToPersianDatePipe,
    ActionTranslatePipe,
    ConvertSizeFilePipe,
    DatePartPipe,
    GetTimeIntervalPipe,
    HighlighterPipe,
    YearOrMonthSuffixPipe
  ],
  providers: [ToPersianDatePipe],
})
export class PipesModule {}
