import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Locale } from '../../models/locale.model';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-modal-helper',
  templateUrl: './modal-helper.component.html',
  styleUrls: ['./modal-helper.component.scss'],
})
export class ModalHelperComponent implements OnInit {
  Desc: string = '';
  locales: Locale[] = [];
  locale: Locale = new Locale();
  translations: { [id: string]: string } = {};
  Label: string = 'Explain';

  constructor(
    public dialogRef: MatDialogRef<ModalHelperComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      Title: string;
      Message: string;
      bDesc: boolean;
      Label: string;
      notShowCloseBtn: boolean;
    },
    private translation: TranslationService
  ) {
    this.translation.getTranslations(this.translations).subscribe((q) => {
      this.translations = q;
    });
    this.locale = JSON.parse(localStorage.getItem('adminLocale') ?? '[{}]');
    this.locales = JSON.parse(localStorage.getItem('adminLocales') ?? '[{}]');
    this.translations['ok'] = 'Ok';
    this.translations['text-button-cancel'] = 'Cancel';
    this.translations['customer-logout-message'] = 'Customer log out message';
    this.translations['textLogOut'] = 'Are you sure about logging out?';
    this.translations['userLogOut'] = 'User exit';
    this.Label = this.data.Label ?? 'Explain';
  }
  ngOnInit(): void {
    this.translation
      .getTranslations(this.translations, 'adminLocale')
      .subscribe((q) => {
        this.translations = q;
      });
  }
}
