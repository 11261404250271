import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { EMPTY } from '../../classes/guid';
import { DynamicTableHeader } from '../../models/dynamic-table-header';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DynamicTableOption } from '../../models/dynamic-table-option';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-dynamic-table-fill',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './dynamic-table-fill.component.html',
  styleUrl: './dynamic-table-fill.component.scss',
})
export class DynamicTableFillComponent implements OnInit, OnChanges {
  @Input({ required: true }) dynamicTableHeaderId: string = EMPTY;
  @Input() dynamicTableFillId: string = EMPTY;
  @Input() mobile: string = '';
  @Input() email: string = '';
  item: DynamicTableHeader | null = null;
  values: { [dynamicTableBodyId: string]: DynamicTableOption } = {};
  isWorking = false;
  submited = false;
  step = 0;
  minStep = 0;
  maxStep = 0;
    translations: { [id: string]: string } = {};

  constructor(
    private api: HttpService,
    private activatedRoute: ActivatedRoute,
    private translation: TranslationService
  ) {
    this.translations['SUBMIT_TITLE'] = 'Submit';
    this.translations['FORM_SUBMITTED'] = 'Form Submitted Successfully.';
  }

  ngOnInit() {
    this.getTranslations();
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.dynamicTableHeaderId = params.id;
      }
      if (params.fid) {
        this.dynamicTableFillId = params.fid;
      }

      this.refresh();
    });
  }
  getTranslations() {
    this.translation
      .getTranslations(this.translations, 'adminLocale')
      .subscribe((q) => {
        this.translations = q;
      });
  }
  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    this.isWorking = true;
    this.api
      .request<DynamicTableHeader>(
        'DynamicTable',
        'GetHeader',
        { id: this.dynamicTableHeaderId, fid: this.dynamicTableFillId },
        'user',
        'GET'
      )
      .subscribe((q) => {
        this.item = q;

      q.dynamicTableBodies?.forEach(w => {
        if (w.dynamicTableBodyId) {
          if (w.fieldType === 7 && this.mobile?.length) {
            this.values[w.dynamicTableBodyId] = { value: this.mobile, dynamicTableOptionId: EMPTY };
          } else if (w.fieldType === 8 && this.email?.length) {
            this.values[w.dynamicTableBodyId] = { value: this.email, dynamicTableOptionId: EMPTY };
          } else {
            this.values[w.dynamicTableBodyId] = { value: '', dynamicTableOptionId: EMPTY };
          }
        }
      });
      if (q.values && q.values.length) {
        q.values.forEach(w => {
          let f = q.dynamicTableBodies?.find(e => e.dynamicTableBodyId === w.dynamicTableBodyId)?.dynamicTableOptions?.find(e => e.dynamicTableOptionId === w.dynamicTableOptionId);
          if (f) {
            this.values[w.dynamicTableBodyId] = f;
          } else {
            this.values[w.dynamicTableBodyId] = {
              value: w.value, dynamicTableOptionId: EMPTY
            };
          }
        });
      }
      if (q.dynamicTableBodies) {
        this.step = this.minStep = Math.min(...q.dynamicTableBodies.map(w => w.step ?? 0));
        this.maxStep = Math.max(...q.dynamicTableBodies.map(w => w.step ?? 0));
      }
      this.isWorking = false;
    });
  }

  addEditSubmit() {
    this.isWorking = true;
    let vals: { [dynamicTableBodyId: string]: { value: string, dynamicTableOptionId: string | null } } = {};
    if (this.item?.dynamicTableBodies?.length) {
      this.item.dynamicTableBodies.forEach(q => {
        if (q.dynamicTableBodyId && this.values[q.dynamicTableBodyId]) {
          let val = this.values[q.dynamicTableBodyId];
          let x: { value: string, dynamicTableOptionId: string | null } = { value: val.value ?? '', dynamicTableOptionId: val.dynamicTableOptionId ?? null };
          vals[q.dynamicTableBodyId] = x;
        }
      });
    }
    this.api.request('DynamicTable', 'PutFill', { dynamicTableHeaderId: this.dynamicTableHeaderId, dynamicTableFillId: this.dynamicTableFillId, values: vals }, 'user', 'PUT').subscribe(() => {
      this.isWorking = false;

        // TODO: Show form complete
        this.submited = true;
      });
  }

  continueClick() {
    this.step++;
  }

  backClick() {
    this.step--;
  }
}
