<ng-container *ngIf="l">
  <router-outlet></router-outlet>
</ng-container>

<div id="recaptcha-container"
     *ngIf="isUsedRecaptcha && mustLoadRecaptcha"
     data-callback="resolved"
     data-error-callback="errored"
     data-sitekey="6Lc5BboaAAAAABKwYfOCCu81864q8rrlAfta9qpX"
     data-size="invisible">
</div>
