import {Component, OnInit} from '@angular/core';
import { Locale } from './shared/models/locale.model';
import { HttpService } from './shared/services/http.service';
import { MatIconRegistry } from '@angular/material/icon';
import { CurrencyUnit } from './shared/models/currnecy-unit.model';
import { SignalhubService } from './shared/services/signalhub.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RecaptchaService } from './shared/services/recaptcha.service';
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  grecaptcha: any | null = null;
  locales: Locale[] = [];
  l: boolean = false;
  mustLoadRecaptcha: boolean = true;
  isUsedRecaptcha: boolean = true;

  constructor(
    public api: HttpService,
    private recaptchaService: RecaptchaService,
    iconRegistry: MatIconRegistry,
    public router: Router,
    private snackBar: MatSnackBar,
    private signalR: SignalhubService,
    private route: ActivatedRoute
  ) {
    let recaptcha_script = document.getElementById('recaptcha_script');
    if (!recaptcha_script) {
      setTimeout(() => {
        (<any>window).resolved = (captchaResponse: string) => {
          this.resolved(captchaResponse);
        };

        (<any>window).errored = () => {
          this.errored();
        };

        (<any>window).onloadCallback = () => {
          this.grecaptcha = (<any>window).grecaptcha;
          this.grecaptcha.render('recaptcha-container', {
            sitekey: '6Lc5BboaAAAAABKwYfOCCu81864q8rrlAfta9qpX',
            callback: (<any>window).resolved,
            error: (<any>window).errored,
          });
        };

        recaptcha_script = document.createElement('script');
        recaptcha_script.setAttribute('id', 'recaptcha_script');
        recaptcha_script.setAttribute('async', 'async');
        recaptcha_script.setAttribute('defer', 'defer');
        recaptcha_script.setAttribute(
          'src',
          'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit'
        );
        document.head.appendChild(recaptcha_script);
      }, 5000);
    }

    iconRegistry.registerFontClassAlias('rasamfont', 'Rasamfontclass');
  }

  ngOnInit(): void {
    //console.log('app init');
    // Global Guid
    let randomGuid = localStorage.getItem('RandomGuid');

    // recaptcha Service
    this.recaptchaService.reseted.subscribe(() => {
      this.grecaptcha?.reset();
    });
    this.recaptchaService.executed.subscribe(() => {
      this.grecaptcha?.execute();
    });
    this.api.getStateRecaptcha().subscribe((q) => {
      this.isUsedRecaptcha = q;
      this.api.isUsedRecaptcha = q;
    });

    // This tab Guid
    this.api
      .request('User', 'GetUniqueGuid', '', 'user', 'GET')
      .subscribe((res: any) => {
        this.api.uniqueGuid = res.uniqueGuid;
      });

    // Recaptcha
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
      //if (event instanceof NavigationEnd) {
        if (
          (<any>this.route.root.firstChild?.snapshot.data)['preload'] ===
          'client'
        ) {
          setTimeout(() => {
            this.mustLoadRecaptcha = true;
          }, 0);
        } else {
          this.mustLoadRecaptcha = true;
        }
      //}
    });

    // Get Language
    this.api
      .request<Locale[]>('Locale', 'GetAllLocale')
      .subscribe((locales) => {
        localStorage.setItem('locales', JSON.stringify(locales));
        if (
          !localStorage.getItem('locale') ||
          localStorage.getItem('locale') === 'null' ||
          localStorage.getItem('locale') === 'undefined' ||
          localStorage.getItem('locale') === '{}'
        ) {
          localStorage.setItem(
            'locale',
            JSON.stringify(locales.find((q) => q.isDefault) ?? {})
          );
        } else {
          let locale: Locale = JSON.parse(localStorage.getItem('locale')!);
          if (!locale.flagNavigation || !locale.flagNavigation.uniqueName) {
            localStorage.setItem(
              'locale',
              JSON.stringify(locales.find((q) => q.key === locale.key) ?? {})
            );
          }
        }
        this.changeLange(
          JSON.parse(localStorage.getItem('locale') ?? '{}')
        );
        //
        localStorage.setItem('adminLocales', JSON.stringify(locales));
        if (
          !localStorage.getItem('adminLocale') ||
          localStorage.getItem('adminLocale') === 'null' ||
          localStorage.getItem('adminLocale') === 'undefined' ||
          localStorage.getItem('adminLocale') === '{}'
        ) {
          localStorage.setItem(
            'adminLocale',
            JSON.stringify(locales.find((q) => q.isDefault) ?? {})
          );
        } else {
          let locale: Locale = JSON.parse(localStorage.getItem('adminLocale')!);
          if (!locale.flagNavigation || !locale.flagNavigation.uniqueName) {
            localStorage.setItem(
              'adminLocale',
              JSON.stringify(locales.find((q) => q.key === locale.key) ?? {})
            );
          }
        }
        this.changeLangeAdmin(
          JSON.parse(localStorage.getItem('adminLocale') ?? '{}')
        );
      });

    this.setCurrency();

    this.api
      .request<CurrencyUnit[]>(
        'CurrencyUnit',
        'GetAllCurrencyUnit',
        '',
        'user',
        'GET'
      )
      .subscribe((currencyUnits) => {
        //console.log('cu GetAllCurrencyUnit response');
        localStorage.setItem('currencyUnits', JSON.stringify(currencyUnits));
      });

    // signalR
    if (window.self === window.top) {
      if (randomGuid) {
        this.signalR.signalhubconnect();
        this.signalR.connection?.on('UserOnlineName', (data: any) => {
          this.api.userOnlineNameChange.next(data);
        });
        this.signalR.connection?.on('UserOnline', (data: any) => {
          this.api.userOnlineChange.next(data);
        });
      } else {
        this.api
          .request('User', 'GetRandomGuid', '', 'user', 'GET')
          .subscribe((res: any) => {
            localStorage.setItem('RandomGuid', res.randomGuid);
            this.signalR.signalhubconnect();
            this.signalR.connection?.on('UserOnlineName', (data: any) => {
              this.api.userOnlineNameChange.next(data);
            });
            this.signalR.connection?.on('UserOnline', (data: any) => {
              this.api.userOnlineChange.next(data);
            });
          });
      }
    }
  }

  setCurrency() {
    this.api.request<CurrencyUnit>('CurrencyUnit','GetCustomerCurrencyUnit','','customer','GET' )
      .subscribe((q) => {
        localStorage.setItem('currencyUnit', JSON.stringify(q));
    });
  }


  changeLange(locale: Locale) {
    localStorage.setItem('locale', JSON.stringify(locale) ?? '{}');
    let html = document.getElementsByTagName('html')[0];
    html.setAttribute('lang', locale.key!);
    html.style.textAlign = locale.isRtl ? 'right' : 'left';
    html.setAttribute('dir', locale.isRtl ? 'rtl' : 'ltr');
    this.l = true;
  }

  changeLangeAdmin(locale: Locale) {
    localStorage.setItem('adminLocale', JSON.stringify(locale) ?? '{}');
    let html = document.getElementsByTagName('html')[0];
    html.setAttribute('lang', locale.key!);
    html.style.textAlign = locale.isRtl ? 'right' : 'left';
    html.setAttribute('dir', locale.isRtl ? 'rtl' : 'ltr');
    this.l = true;
  }

  resolved(captchaResponse: string | null): void {
    if (captchaResponse) {
      this.recaptchaService.resolve(captchaResponse);
    }
  }

  errored(): void {
    this.grecaptcha?.reset();
    //todo: خطای امنیتی
    this.snackBar.open(`recaptcha error`, 'ok', {
      duration: 5000,
      direction: 'ltr',
    });
  }
}
