import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MaterialCustomDateAdapter, Custom_DATE_FORMATS } from "./material-custom-date.adapter";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MaterialCustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: Custom_DATE_FORMATS }
  ]
})
export class MaterialPersianDateModule { }
